/* eslint-disable unicorn/prevent-abbreviations */
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import {
  PaintBlockSelectionPill,
  PaintBlockSelectionPillCounter,
  StyledToggleButtonGroup,
} from './styles';
import { useRecoilState } from 'recoil';
import * as state from '@/components/Analysis/state';
import { usePaintRegionGroupsQuery } from '@/__generated__/graphql';
import { useMemo } from 'react';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { Skeleton } from '@mui/material';

type Props = {
  compact?: boolean;
} & ToggleButtonGroupProps;

export const PaintBlockTopFilter = ({ sx, compact = false, ...toggleGroupProps }: Props) => {
  const structureId = useGetStructureId();

  const [selectedPaintRegionGroup, setelectedPaintRegionGroup] = useRecoilState(
    state.selectedPaintRegionGroup
  );

  const { data, loading } = usePaintRegionGroupsQuery({
    variables: {
      input: { structureId },
    },
    onCompleted: (response) => {
      // Initializing the filter to the default filter
      // which is 'Paint Regions'
      const defaultPaintGroupId = response?.paintRegionGroups?.find((item) => item.isDefault);
      if (defaultPaintGroupId) {
        setelectedPaintRegionGroup((previous) => {
          // If there's already a filter set, don't interfere
          return previous || defaultPaintGroupId;
        });
      }
    },
  });

  const options = useMemo(() => {
    return (data?.paintRegionGroups || []).map((group) => ({
      value: group?.id,
      label: group?.name,
      count: 0,
    }));
  }, [data?.paintRegionGroups]);

  if (loading)
    return (
      <Skeleton variant="rectangular" width={'70%'} height={30} sx={{ borderRadius: 2, mt: 1 }} />
    );

  if (!options?.length) return <></>;

  return (
    <StyledToggleButtonGroup
      value={selectedPaintRegionGroup?.id}
      exclusive
      onChange={(_, value: (typeof options)[number]['value']) => {
        if (!value) return;
        // Ensuring that one filter is always selected
        if (value === selectedPaintRegionGroup?.id) return;

        const foundGroup = data?.paintRegionGroups?.find((group) => group.id === value);
        setelectedPaintRegionGroup(foundGroup);
      }}
      {...toggleGroupProps}
      sx={sx}
    >
      {options.map(({ count, label, value }) => (
        <PaintBlockSelectionPill key={value} value={value}>
          {label}
          {!compact && <PaintBlockSelectionPillCounter>{count}</PaintBlockSelectionPillCounter>}
        </PaintBlockSelectionPill>
      ))}
    </StyledToggleButtonGroup>
  );
};
