import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import {
  ControlsType,
  CuboidStyle,
  CuboidVariants,
  Number3,
  TransformMode,
} from '@abyss/3d-viewer';
import * as cuboidState from '../state';
import { AnalysisCuboid, CuboidClickMode, PartialExcept } from '@/types';
import { generateMongoObjectId } from '@/utils/databaseHelpers';

export const makeCuboidStyle = (
  partialStyles: PartialExcept<CuboidStyle, 'fillColor' | 'lineColor'>
): CuboidStyle => ({
  variant: partialStyles?.variant || CuboidVariants.LinesAndTransparentMesh,
  lineColor: partialStyles?.lineColor,
  lineWidth: partialStyles?.lineWidth || 1,
  lineOpacity: partialStyles?.lineOpacity || 0.8,
  fillColor: partialStyles?.fillColor,
  fillOpacity: partialStyles?.fillOpacity || 0.2,
});

export const useCreateAnalysisCuboid = () => {
  const setCurrentCuboid = useSetRecoilState(cuboidState.currentCuboid);
  const setIsCuboidEditorEnabled = useSetRecoilState(cuboidState.isCuboidEditorEnabled);
  const setCuboidControlsType = useSetRecoilState(cuboidState.cuboidControlsType);
  const setCuboidTransformMode = useSetRecoilState(cuboidState.cuboidTransformMode);
  const setCuboidClickMode = useSetRecoilState(cuboidState.cuboidClickMode);

  // TODO: Implement a check later using default class from structure
  const canCreateCuboid = true;

  const createCuboid = useCallback(
    (position: Number3) => {
      const id = generateMongoObjectId();

      const newCuboid: AnalysisCuboid = {
        id,
        position,
        scale: [1, 1, 1],
        rotation: [0, 0, 0],
      };
      // Enable edit mode for the new cuboid
      setCurrentCuboid(newCuboid);
      setCuboidControlsType(ControlsType.AlignedBox);
      setCuboidTransformMode(TransformMode.Translate);
      setIsCuboidEditorEnabled(true);
      setCuboidClickMode(CuboidClickMode.Default);
    },
    [
      setCurrentCuboid,
      setCuboidControlsType,
      setCuboidTransformMode,
      setIsCuboidEditorEnabled,
      setCuboidClickMode,
    ]
  );

  return { canCreateCuboid, createCuboid };
};
