import { Box } from '@mui/material';
import { PaintBlockTopFilter } from './PaintBlockTopFilter';
import { CustomBlockList } from './CustomBlockList';

export const CustomBlocksTab = () => {
  return (
    <Box sx={{ height: '100%', overflowY: 'auto', p: 1 }}>
      <PaintBlockTopFilter compact sx={{ my: 1, px: 1 }} />
      <CustomBlockList />
    </Box>
  );
};
