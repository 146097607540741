import { PrismVolume } from '@/__generated__/graphql';
import { AnalysisCuboid } from '@/types';
import { Number3 } from '@abyss/3d-viewer';

export const calculatePointOnRayWithDistance = (
  rayOrigin: Number3,
  lookAt: Number3,
  distance: number
): Number3 => {
  const [ox, oy, oz] = rayOrigin;
  const [tx, ty, tz] = lookAt;
  const dx = tx - ox;
  const dy = ty - oy;
  const dz = tz - oz;
  const scale = distance / Math.hypot(dx, dy, dz);
  return [ox + dx * scale, oy + dy * scale, oz + dz * scale];
};

export const calculateDistance3d = (a: Number3, b: Number3): number => {
  const [ax, ay, az] = a;
  const [bx, by, bz] = b;
  const dx = ax - bx;
  const dy = ay - by;
  const dz = az - bz;
  const distance = Math.hypot(dx, dy, dz);
  return distance;
};

export const toNumber3 = (array: number[]): Number3 => {
  if (array.length !== 3) {
    throw new Error('toNumber3: array length is not 3');
  }
  return array as Number3;
};

export function calculateBoundingBox(points: number[][]): {
  center: [number, number];
  width: number;
  height: number;
  rotation: number;
} {
  // Find min/max coordinates
  const xs = points.map((p) => p[0]);
  const ys = points.map((p) => p[1]);
  const minX = Math.min(...xs);
  const maxX = Math.max(...xs);
  const minY = Math.min(...ys);
  const maxY = Math.max(...ys);

  // Calculate center
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  // Calculate dimensions
  const width = maxX - minX;
  const height = maxY - minY;

  // For simplicity, we'll assume the rotation based on the first two points
  // This is a simplified approach - for more complex polygons you might want
  // to use principal component analysis or other methods
  const firstPoint = points[0];
  const secondPoint = points[1];
  const rotation = Math.atan2(secondPoint[1] - firstPoint[1], secondPoint[0] - firstPoint[0]);

  return {
    center: [centerX, centerY],
    width,
    height,
    rotation,
  };
}

export function prismToAnalysisCuboid(
  prismData: PrismVolume,
  id: string,
  poiId?: string
): AnalysisCuboid {
  // Calculate bounding box for the xy polygon
  const boundingBox = calculateBoundingBox(prismData.xy_polygon);

  // Calculate z center and height
  const zCenter = (prismData.z_range[0] + prismData.z_range[1]) / 2;
  const zHeight = prismData.z_range[1] - prismData.z_range[0];

  // Create the cuboid
  const cuboid: AnalysisCuboid = {
    id,
    position: [boundingBox.center[0], boundingBox.center[1], zCenter],
    rotation: [0, 0, boundingBox.rotation],
    scale: [boundingBox.width, boundingBox.height, zHeight],
    ...(poiId && { poiId }),
  };

  return cuboid;
}
