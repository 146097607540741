import { useRecoilValue } from 'recoil';
import * as state from '../state';
import { Cuboid } from '@abyss/3d-viewer';
import { useCallback } from 'react';
import { AnalysisCuboid } from '@/types';
import { makeCuboidPropsFromAnalysisCuboid, useGetAnalysisCuboidColor } from '../../cuboids/hooks';

export const CustomBlockCuboids = () => {
  const customBlockCuboids = useRecoilValue(state.customBlockCuboids);
  const getCuboidColor = useGetAnalysisCuboidColor();

  const makeCuboidProps = useCallback(
    (cuboid: AnalysisCuboid) => {
      const color = getCuboidColor();
      return makeCuboidPropsFromAnalysisCuboid(cuboid, {
        fillColor: color,
        lineColor: color,
        lineWidth: 2,
        fillOpacity: 0.1,
      });
    },
    [getCuboidColor]
  );

  return (
    <>
      {customBlockCuboids.map((cuboid) => {
        return <Cuboid {...makeCuboidProps(cuboid)} />;
      })}
    </>
  );
};
