import {
  DefectsAreaForCustomBlocksQuery,
  useDefectsAreaForCustomBlocksQuery,
} from '@/__generated__/graphql';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { useRecoilValue } from 'recoil';
import * as state from '@/components/Analysis/state';
import { useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { CustomBlockListItem } from './CustomBlockListItem';
import { CustomBlockListHeader } from './CustomBlockListHeader';
import { CustomBlockListSearch } from './CustomBlockListSearch';
import { FixedSizeList as List } from 'react-window';

type RowProps = {
  index: number;
  style: React.CSSProperties;
  data: DefectsAreaForCustomBlocksQuery['defectsAreaForAssemblyPaintRegionsByGroup']['defectsByPaintBlock'];
};

const Row = ({ index, style, data }: RowProps) => {
  const block = data[index];
  return (
    <div style={style}>
      <CustomBlockListItem key={block.paintRegionId} block={block} />
    </div>
  );
};

export const CustomBlockList = () => {
  const structureId = useGetStructureId();
  const selectedPaintRegionGroup = useRecoilValue(state.selectedPaintRegionGroup);
  const [searchTerm, setSearchTerm] = useState('');

  const { data, loading } = useDefectsAreaForCustomBlocksQuery({
    variables: {
      input: {
        structureIds: [structureId],
        paintRegionGroupIds: selectedPaintRegionGroup?.id
          ? [selectedPaintRegionGroup.id]
          : undefined,
      },
    },
    skip: !selectedPaintRegionGroup || selectedPaintRegionGroup?.isDefault,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    initialFetchPolicy: 'cache-first',
  });

  const blocks = useMemo(() => {
    const items = data?.defectsAreaForAssemblyPaintRegionsByGroup?.defectsByPaintBlock || [];
    if (!searchTerm) return items;
    const filteredItems = items.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredItems;
  }, [data?.defectsAreaForAssemblyPaintRegionsByGroup?.defectsByPaintBlock, searchTerm]);

  if (loading) return <p>Loading...</p>;

  if (blocks.length === 0) return <p>No data found</p>;

  return (
    <Stack>
      <CustomBlockListHeader count={blocks.length} mt={2} mb={1} />
      <CustomBlockListSearch onSearch={setSearchTerm} value={searchTerm} />
      <List
        height={720} // Adjust based on your UI
        itemCount={blocks.length}
        itemSize={50} // Adjust based on item height
        width="100%"
        itemData={blocks}
      >
        {Row}
      </List>
    </Stack>
  );
};
