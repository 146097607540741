import { Typography, Box, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { stringFromDateOrString } from './utils';
import { ReadModeProps } from './types';
import { theme } from '@/theme/theme';

const boxRootStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1.6rem',
};

export const ReadOnlyTemplateField = ({
  value,
  title,
  underlined,
  sx: styles,
  truncate,
  valueTooltip,
  ...props
}: ReadModeProps) => {
  const stringifiedValue = stringFromDateOrString(value) ?? 'N/A';

  const truncatedValue = useMemo(() => {
    if (!truncate || !truncate.cutoff) return stringifiedValue;

    if (typeof truncate.cutoff === 'number') {
      return stringifiedValue.slice(0, truncate.cutoff);
    }
  }, [stringifiedValue, truncate]);

  return (
    <Box sx={{ ...boxRootStyle, ...styles }} {...props}>
      <Typography fontSize="1.2rem" fontWeight="500" whiteSpace={truncate ? 'nowrap' : 'initial'}>
        {title}
      </Typography>
      <Tooltip title={valueTooltip} placement="top" arrow>
        <Typography
          fontSize="1.4rem"
          style={{
            textAlign: 'right',
            fontWeight: 900,
            textDecoration: underlined ? 'underline' : '',
            cursor: underlined ? 'pointer' : 'default',
            wordBreak: truncate ? 'keep-all' : 'break-all',
            color: underlined ? theme.palette.common.darkBlue : theme.palette.text.primary,
            ...(truncate
              ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
              : {}),
          }}
        >
          {truncate && typeof truncate.cutoff === 'number' ? truncatedValue : stringifiedValue}
        </Typography>
      </Tooltip>
    </Box>
  );
};
