import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useCallback, SetStateAction } from 'react';

type Props = {
  crossSectionIsUnlocked: boolean;
  setCrossSectionIsUnlocked: (value: SetStateAction<boolean>) => void;
};

export const formControlLabelStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: 0,
  // margin: '0 0 6px',
  color: '#50739b',
  backgroundColor: '#e7eef6',
  padding: '6px',
};

export const UnlockCrossSectionToggle = ({
  crossSectionIsUnlocked,
  setCrossSectionIsUnlocked,
}: Props) => {
  const toggleCrossSectionIsUnlocked = useCallback(() => {
    setCrossSectionIsUnlocked((current) => !current);
  }, [setCrossSectionIsUnlocked]);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={crossSectionIsUnlocked}
          onChange={() => toggleCrossSectionIsUnlocked()}
          size="small"
        />
      }
      label={
        <Stack direction="row" spacing={1} alignItems="center">
          <InfoIconOutlined sx={{ fontSize: '1.5rem' }} />
          <Typography sx={{ fontSize: '1.2rem', lineHeight: '1.5rem' }}>
            Unlock the 2D profile first to update it by clicking on the 3D profile.
          </Typography>
        </Stack>
      }
      labelPlacement="start"
      sx={formControlLabelStyles}
    />
  );
};
