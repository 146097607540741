import { useMemo, ReactNode, SyntheticEvent, useCallback } from 'react';
import { Tabs, Tab, Box, Stack } from '@mui/material';
import { TabsRenderingStrategy } from '@/types';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
  renderingStrategy?: TabsRenderingStrategy;
};

export type TabType = {
  index: number;
  label: string;
  component: ReactNode;
  testid?: string;
};

type BasicTabsProps = {
  tabs: TabType[];
  selectedTab: number;
  changeTab: (selectedTabIndex: number) => void;
  fullWidth?: boolean;
  variant?: 'pills' | 'default';
  tabsStyles?: Object;
  tabsListRightMargin?: string;
  hidden?: boolean;
  tabsBackgroundColor?: string;
  handleResetFilters?: () => void;
  renderingStrategy?: TabsRenderingStrategy;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, renderingStrategy, ...other } = props;

  const renderedChildren: React.ReactNode = useMemo(() => {
    // If the strategy is eager, keep all the children rendered
    // but set the display to none for the ones that are not selected
    // this ensures, the components are mounted and their state is not lost
    if (renderingStrategy === 'eager')
      return <div style={{ display: value === index ? 'block' : 'none' }}>{children}</div>;

    // in case renderingStrategy is lazy, or not provided, load only the selected tab
    // any other tab will not be rendered, and once a tab is unseen, it will be unmounted
    // and all its state will be lost
    return value === index ? children : <></>;
  }, [renderingStrategy, children, value, index]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ height: '100%' }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {renderedChildren}
    </div>
  );
}

export function BasicTabs({
  tabs,
  selectedTab,
  changeTab,
  fullWidth = true,
  variant = 'default',
  tabsStyles = {},
  tabsListRightMargin = 'inherit',
  hidden = false,
  tabsBackgroundColor = 'inherit',
  handleResetFilters,
  renderingStrategy = 'lazy',
}: BasicTabsProps) {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    changeTab(newValue);
    if (handleResetFilters) handleResetFilters();
  };

  const TabIndicatorProps = useMemo(() => {
    if (variant === 'pills') {
      return {
        style: {
          display: 'none',
        },
      };
    }
    return {};
  }, [variant]);

  const getTabStyles = useCallback(
    (index: number) => {
      if (variant === 'pills') {
        const isTabSelected = selectedTab === index;
        return {
          flex: fullWidth ? 1 : 'unset',
          background: isTabSelected ? 'aliceblue' : 'unset',
          fontSize: 'caption.fontSize',
          borderRadius: 2,
        };
      }
      return { flex: fullWidth ? 1 : 'unset' };
    },
    [fullWidth, selectedTab, variant]
  );

  return (
    <Stack
      alignItems="stretch"
      direction="column"
      sx={{ height: '100%', position: 'relative', display: hidden ? 'none' : 'flex' }}
    >
      <Box
        sx={{
          borderBottom: variant === 'default' ? 1 : 0,
          borderColor: 'divider',
          padding: 0,
          marginRight: tabsListRightMargin,
          backgroundColor: tabsBackgroundColor,
        }}
      >
        <Tabs
          sx={{
            ...(variant === 'default'
              ? {
                  '@media (max-width: 1728px)': {
                    minHeight: '36px',
                    '& button': {
                      padding: 0,
                      minHeight: '36px',
                      minWidth: 0,
                    },
                  },
                  '& button': {
                    textTransform: 'none',
                    fontWeight: 400,
                  },
                  '& .Mui-selected': { fontWeight: 500 },
                }
              : {}),
            ...tabsStyles,
            display: 'flex',
            width: '100%',
          }}
          value={selectedTab}
          onChange={handleChange}
          TabIndicatorProps={TabIndicatorProps}
        >
          {tabs.map((tab, index) => {
            const isSelected = selectedTab === tab.index;
            return (
              <Tab
                sx={getTabStyles(index)}
                label={tab.label}
                key={tab.index}
                value={tab.index}
                hidden={isSelected}
                data-testid={tab?.testid ?? ''}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabs.map((tab) => (
        <TabPanel
          value={selectedTab}
          index={tab.index}
          key={tab.index}
          renderingStrategy={renderingStrategy}
        >
          {tab.component}
        </TabPanel>
      ))}
    </Stack>
  );
}
