import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FilterList from '@mui/icons-material/FilterList';
import { BasicTabs } from '@/components/shared/BasicTabs';
import { poiState, PointOfInterestTab } from '@/components/Analysis/modules/pointOfInterest';
import { EquipmentsTab } from '@/components/Analysis/shared/EquipmentsTab';
import * as analysisState from '@/components/Analysis/state';
import {
  DrawersProps,
  PointOfInterestChecked,
  PointOfInterestWithCuboids,
  SelectedTab,
} from '@/types';
import { SideDrawer } from '@/components/shared/SideDrawer';
import { GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery } from '@/__generated__/graphql';
import useAbility from '@/hooks/useAbility';
import useAssemblyDetails from '@/hooks/useAssemblyDetails';
import { useDrawerWidths } from '@/hooks/useDrawerWidths';
import { useAnalysisCuboidActions } from '../../modules/cuboids/state/actions';
import { abyssColors } from '@/theme/colors';
import { CustomBlocksTab } from '../../modules/CustomBlocks';
import { useFeatureFlag } from '@/hooks';
import { TabType } from '@/components/shared/BasicTabs/BasicTabs';

type AssemblyTabsProps = {
  filteredAssemblies:
    | GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery['getAssembliesByTemplateFieldsFilters']
    | undefined;
  assemblyLoading?: boolean;
} & DrawersProps;

export const AssemblyTabs = ({
  filteredAssemblies,
  allClosed,
  isOpen,
  assemblyLoading,
  handleOpen,
}: AssemblyTabsProps) => {
  const setFilteredAssemblyIds = useSetRecoilState(analysisState.filteredAssemblyIds);
  const editPointOfInterest = useRecoilValue(poiState.editPointOfInterest);

  const allPOI = useRecoilValue(poiState.structurePois);
  const isAlphaUser = useFeatureFlag('alpha-user');

  const blisterState = useRecoilValue(poiState.blisterToAdd);

  const [selectedAssemblyId] = useRecoilState(analysisState.selectedAssemblyId);

  const [poiFilteredByCheckbox, setPoiFilteredByCheckbox] = useRecoilState(
    poiState.poiFilteredByCheckbox
  ); // const filteredAssemblies = useRecoilValue(analysisState.filteredAssembliesByTemplateFields);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(SelectedTab.COMPONENT);
  const { ability } = useAbility();
  const canReadPOI = ability.can('read', 'PointOfInterest');

  const { initializeCuboids } = useAnalysisCuboidActions();

  const selectedPointOfInterest = useRecoilValue(poiState.selectedPointOfInterestId);
  const { assembly: assemblyDetails, loading: assemblyDetailsLoading } = useAssemblyDetails();

  useEffect(() => {
    if (selectedPointOfInterest) {
      setSelectedTabIndex(SelectedTab.POINTS_OF_INTEREST);
    }
  }, [selectedPointOfInterest]);

  const changeTab = useCallback((tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  const { rightDrawerWidth, doublePanelWidth } = useDrawerWidths();

  const drawerWidth = useMemo(() => {
    if (editPointOfInterest?.formState === 'Verify') return rightDrawerWidth;
    return blisterState?.state === 'UpdatingBlister' ? doublePanelWidth : rightDrawerWidth;
  }, [blisterState?.state, doublePanelWidth, rightDrawerWidth, editPointOfInterest?.formState]);

  // Only get the POIs that exists on the filtered assemblies, exluding the ones that are not in the filtered assemblies
  const filteredPois = useMemo(() => {
    if (!allPOI || allPOI.length === 0) {
      return [];
    }
    // Create a map of the filtered assembly ids to avoid exponential time complexity
    // when filtering the POIs
    const filteredAssemblyIdsMap = new Set(filteredAssemblies?.map(({ id }) => id));
    return allPOI.filter((poi) => filteredAssemblyIdsMap.has(poi.assembly.id));
  }, [allPOI, filteredAssemblies]);

  // If an assembly is selected, only show the POIs for that assembly
  // otherwise show all POIs for the filtered assemblies
  const pois = useMemo(() => {
    return selectedAssemblyId
      ? filteredPois.filter((poi) => selectedAssemblyId === poi.assembly.id)
      : filteredPois;
  }, [filteredPois, selectedAssemblyId]);

  const tabs = useMemo(() => {
    return [
      {
        index: SelectedTab.COMPONENT,
        label: 'Component',
        component: (
          <EquipmentsTab
            assemblies={filteredAssemblies}
            loading={assemblyLoading}
            changeTab={changeTab}
            assemblyDetails={assemblyDetails}
            assemblyDetailsLoading={assemblyDetailsLoading}
          />
        ),
      },
      isAlphaUser
        ? {
            index: SelectedTab.CUSTOM_BLOCKS,
            label: 'Blocks',
            component: <CustomBlocksTab />,
          }
        : undefined,
      {
        index: SelectedTab.POINTS_OF_INTEREST,
        label: 'Points of interest',
        component: (
          <PointOfInterestTab pois={pois as PointOfInterestChecked[]} changeTab={changeTab} />
        ),
      },
    ]
      .filter(Boolean)
      .filter((item) => item?.label !== 'Points of Interest' || canReadPOI) as TabType[];
  }, [
    filteredAssemblies,
    assemblyLoading,
    changeTab,
    assemblyDetails,
    assemblyDetailsLoading,
    pois,
    canReadPOI,
    isAlphaUser,
  ]);

  useEffect(() => {
    // initialise the poiFilteredByCheckbox with all pois for this equipment/assembly
    // when an assembly is selected
    setPoiFilteredByCheckbox(pois as PointOfInterestChecked[]);
  }, [pois, setPoiFilteredByCheckbox]);

  useEffect(() => {
    if (poiFilteredByCheckbox) {
      initializeCuboids(poiFilteredByCheckbox as PointOfInterestWithCuboids[]);
    }
    // Adding initializeCuboids to the dependency array causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poiFilteredByCheckbox]);

  useEffect(() => {
    const filteredAssemblyIds = filteredAssemblies ? filteredAssemblies.map(({ id }) => id) : [];

    // this line is all that needs to be set to highlight the asset results in the spacial viewer
    // feel free to change if needed
    setFilteredAssemblyIds(filteredAssemblyIds);
  }, [filteredAssemblies, setFilteredAssemblyIds]);

  return (
    <SideDrawer
      allDrawersClosed={allClosed}
      icon={<FilterList />}
      position="right"
      width={drawerWidth}
      isOpen={isOpen}
      handleOpen={handleOpen}
      iconStyles={{ top: 10 }}
      sxPaper={{ overflowY: 'hidden' }}
    >
      <BasicTabs
        tabs={tabs}
        selectedTab={selectedTabIndex}
        tabsBackgroundColor={abyssColors.primary[50]}
        changeTab={changeTab}
      />
    </SideDrawer>
  );
};
