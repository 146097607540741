import { CuboidProps, CuboidStyle } from '@abyss/3d-viewer';
import { toNumber3 } from '@/utils/geometry';
import { AnalysisCuboid, PartialExcept } from '@/types';
import { makeCuboidStyle } from './useCreateCuboid';

export const makeCuboidPropsFromAnalysisCuboid = (
  cuboid: AnalysisCuboid,
  style: PartialExcept<CuboidStyle, 'fillColor' | 'lineColor'>
): CuboidProps => {
  const { id, position, scale, rotation } = cuboid;
  const cuboidStyles = makeCuboidStyle(style);
  return {
    id,
    name: id,
    position: toNumber3(position),
    scale: toNumber3(scale),
    rotation: toNumber3(rotation),
    style: cuboidStyles,
    enableClippingByVisibilityBox: false,
  };
};
