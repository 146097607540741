import { Input } from '@mui/material';
import { memo } from 'react';

type Props = {
  onSearch: (searchTerm: string) => void;
  value: string;
};
export const CustomBlockListSearch = memo(({ onSearch, value }: Props) => {
  return (
    <Input placeholder="Search" value={value} onChange={(event) => onSearch(event.target.value)} />
  );
});
