import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useMemo } from 'react';

import { convertMetresToFeetDisplay, getMetresDisplay } from '@/utils/unitSystem';
import * as state from '@/components/Analysis/state';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { Viewpoint } from '@/types';

export const useViewpointsByAssembly = (
  assemblyId: string | undefined,
  seenByViewpoints: Viewpoint[] | undefined
): Viewpoint[] => {
  const unitSystem = useRecoilValue(state.unitSystem);
  const [viewpointsByAssemblyId, setViewpointsByAssemblyId] = useRecoilState(
    state.viewpointsByAssemblyId
  );

  useEffect(() => {
    const assemblyViewpoints = seenByViewpoints?.map((viewpoint: Viewpoint) => {
      return {
        ...viewpoint,
        distanceDisplayText:
          unitSystem === UnitSystemEnum.Imperial
            ? convertMetresToFeetDisplay(viewpoint.distance)
            : getMetresDisplay(viewpoint.distance),
      };
    });
    if (assemblyViewpoints && assemblyId && viewpointsByAssemblyId[assemblyId] === undefined) {
      setViewpointsByAssemblyId((current) => ({
        ...current,
        [assemblyId]: assemblyViewpoints,
      }));
    }
  }, [assemblyId, seenByViewpoints, setViewpointsByAssemblyId, unitSystem, viewpointsByAssemblyId]);

  const viewpoints = useMemo(() => {
    return assemblyId ? viewpointsByAssemblyId[assemblyId] : undefined;
  }, [viewpointsByAssemblyId, assemblyId]);

  return viewpoints ?? [];
};
