import { createTheme } from '@mui/material/styles';
import { severityBgColor, severityColor } from '@/theme/colors';
import { getScale } from '@/utils';
import * as overrides from './overrides';

declare module '@mui/material/styles' {
  interface CommonColors {
    darkBlue: string;
  }
}

const baseTheme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: '#224063',
      light: '#50739B',
      dark: '#1A2C41',
      contrastText: '#F6F8FB',
      50: '#F6F8FB',
      100: '#E7EEF6',
      200: '#B8CADD',
      300: '#7E9BBC',
      400: '#50739B',
      500: '#224063',
      600: '#203752',
      700: '#1A2C41',
      800: '#1B2838',
      900: '#131E2D',
    },
    secondary: {
      main: '#3BDAB7',
      light: '#E7EEF6',
      50: '#E0F8F2',
      100: '#C1F4E8',
      200: '#A9F2E0',
      300: '#7DEED3',
      400: '#4CE3C2',
      500: '#3BDAB7',
      600: '#35C2A3',
      700: '#169985',
      800: '#178170',
      900: '#09695B',
    },
    warning: {
      main: '#BF5600',
      50: '#FEFCE8',
      100: '#FEF9C3',
      200: '#FEF08A',
      300: '#FDE047',
      400: '#FACC15',
      500: '#EAB308',
      600: '#CA8A04',
      700: '#A16207',
      800: '#854D0E',
      900: '#713F12',
    },
    error: {
      main: '#C62828',
      light: '#DD00044D',
      dark: '#AA0003',
      50: '#FFE7E7',
      100: '#FFCDCF',
      200: '#FFA4A7',
      300: '#FF9395',
      400: '#FF7E80',
      500: '#FF5D60',
      600: '#DD0004',
      700: '#AA0003',
      800: '#770002',
      900: '#440001',
    },
    success: {
      50: '#F0FDF4',
      100: '#DCFCE7',
      200: '#BBF7D0',
      300: '#86EFAC',
      400: '#4ADE80',
      500: '#22C55E',
      600: '#16A34A',
      700: '#15803D',
      800: '#166534',
      900: '#14532D',
    },
    action: {
      selected: '#6582A4',
    },
    text: {
      primary: '#203752',
      secondary: '#7E9BBC',
      disabled: '#7E9BBC',
    },
    common: {
      darkBlue: '#0C7CBB',
    },
    info: {
      main: '#50739B',
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0 * getScale(),
      sm: 600 * getScale(),
      md: 1280 * getScale(),
      lg: 1728 * getScale(),
      xl: 1920 * getScale(),
    },
  },
});

export const theme = createTheme(baseTheme, {
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td, &:last-child th': {
            border: 0,
          },
          '&.MuiTableRow-hover:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiButton: overrides.MuiButton(baseTheme),
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: severityBgColor.success,
          color: severityColor.success,
        },
        standardInfo: {
          backgroundColor: severityBgColor.info,
          color: severityColor.info,
        },
      },
    },
  },
});

export const darkTheme = createTheme(theme, {
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',
      dark: '#ffffff',
      light: '#444444',
      contrastText: '#000000',
    },
    secondary: {
      main: '#666666',
      contrastText: '#ffffff',
    },
  },
});

export const elevation = ['0px 2px 8px 0px rgba(0, 0, 0, 0.30)'];
