import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ActionButtonsContainer, BlocksContainer, BlocksText, NumberText } from './styles';

type Props = {
  count: number;
} & BoxProps;

export const CustomBlockListHeader = ({ count, ...props }: Props) => {
  return (
    <BlocksContainer {...props}>
      <Box display="flex" alignItems="baseline">
        <NumberText>{count}</NumberText>
        <BlocksText>Blocks</BlocksText>
      </Box>

      <ActionButtonsContainer>
        <IconButton size="small" aria-label="sort options">
          <TuneIcon />
        </IconButton>
        <IconButton size="small" aria-label="navigate up">
          <SwapVertIcon />
        </IconButton>
        <IconButton size="small" aria-label="more options">
          <MoreHorizIcon />
        </IconButton>
      </ActionButtonsContainer>
    </BlocksContainer>
  );
};
