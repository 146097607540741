import {
  GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery,
  GetAssembliesWithTemplateFieldDataQuery,
  GetAssemblyDetailsQuery,
  useGetAssembliesByRiskAssessmentFiltersQuery,
  useGetAssembliesWithTemplateFieldDataLazyQuery,
  useGetAssemblyContextualizedStatusQuery,
} from '@/__generated__/graphql';
import { useAssemblyVisibilityBox } from '@/components/Analysis/modules/assembly';
import { AllEquipmentBackButton } from '@/components/Analysis/modules/pointOfInterest';
import * as state from '@/components/Analysis/state';
import {
  selectedTemplateFiltersState,
  selectedFilteredDeckIds as selectedFilteredDeckIdsState,
  selectedFilteredPaintRegionIds as selectedFilteredPaintRegionIdsState,
} from '@/components/Analysis/state';
import { useResetSelectedFilters } from '@/hooks';
import { useGetStructureId } from '@/hooks/useGetStructureId';
import { Box, Stack, Typography, TextField, InputAdornment, Tooltip, Fade } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { abyssColors } from '@/theme/colors';
import { EmptyStatePlaceholder } from '../EmptyStatePlaceholder';
import { EquipmentCard } from './EquipmentCard';
import { EquipmentDetails } from './EquipmentDetails';
import { IsometricDownloadButton } from './EquipmentDetails/IsometricDownloadButton';
import { EquipmentTabSkeleton } from './EquipmentTabSkeleton';
import { RiskEquipmentCard } from './RiskEquipmentCard';
import { PageLoader } from '@/components/shared/PageLoader';
import {
  EndMessage,
  EquipmentCardsContainer,
  EquipmentHeader,
  EquipmentTabContainer,
  ExportButtonContainer,
  FloatingButton,
  tooltipPopperStyles,
} from './styles';
import { ExportAssetsButton } from '../AnalysisNavbar/ExportAssetsButton';

type Assemblies =
  | GetAssembliesByTemplateFieldsFiltersForEquipmentsTabQuery['getAssembliesByTemplateFieldsFilters']
  | undefined;

type CurrentAssemblies =
  | GetAssembliesWithTemplateFieldDataQuery['getAssembliesByTemplateFieldsFilters']
  | [];

type EquipmentsTabProps = {
  assemblies: Assemblies;
  changeTab: (selectedTabIndex: number) => void;
  loading?: boolean;
  assemblyDetails?: GetAssemblyDetailsQuery['assembly'];
  assemblyDetailsLoading?: boolean;
};
const selectedAssemblyCardStyles = {
  backgroundColor: '#3C3C3C',
  color: 'white',
  mb: '15',
};

const rowsPerPage = 10;

export const EquipmentsTab = ({
  assemblies,
  changeTab,
  loading,
  assemblyDetails,
  assemblyDetailsLoading,
}: EquipmentsTabProps) => {
  const scrollContainerReference = useRef<HTMLDivElement | null>(null);
  const scrollContentReference = useRef<HTMLDivElement | null>(null);
  const [loadMoreAssemblies, setLoadMoreAssemblies] = useState(true);
  const [page, setPage] = useState(0);
  const [assemblyCount, setAssemblyCount] = useState(0);
  const [isAtScrollStart, setIsAtScrollStart] = useState(true);
  const [selectedAssemblyId, setSelectedAssemblyId] = useRecoilState(state.selectedAssemblyId);
  const setSelectedAssemblyName = useSetRecoilState(state.selectedAssemblyName);
  const selectedAssemblyTemplate = useRecoilValue(state.riskAssessmentAssemblyTemplate);
  const allTemplates = useRecoilValue(state.allAssemblyTemplates);
  const [riskSearchTerm, setRiskSearchTerm] = useRecoilState(state.riskAssessmentSearchTerm);
  const selectedTemplateFilters = useRecoilValue(selectedTemplateFiltersState);
  const selectedDeckIds = useRecoilValue(selectedFilteredDeckIdsState);
  const selectedPaintRegionIds = useRecoilValue(selectedFilteredPaintRegionIdsState);
  const [appliedTemplateFilters, setAppliedTemplateFilters] = useState(selectedTemplateFilters);
  const [appliedDeckFilters, setAppliedDeckFilters] = useState(selectedDeckIds);
  const [appliedPaintRegionFilters, setAppliedPaintRegionFilters] =
    useState(selectedPaintRegionIds);
  const [selectedAssemblyInfo, setSelectedAssemblyInfo] = useRecoilState(
    state.selectedAssemblyInfo
  );
  const setSelectedTemplateFilters = useSetRecoilState(state.selectedTemplateFiltersState);
  const selectedSpherical = useRecoilValue(state.selectedSpherical);
  const applyFilters = useRecoilValue(state.applyFilters);
  const selectedAssemblyTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);
  const structureId = useGetStructureId();

  const [currentAssemblies, setCurrentAssemblies] = useState<CurrentAssemblies>([]);

  const [getAssemblies, { loading: assembliesDataLoading }] =
    useGetAssembliesWithTemplateFieldDataLazyQuery({
      onCompleted: (assembliesWithTemplateDataResponse) => {
        setLoadMoreAssemblies(false);
        setAssemblyCount(assembliesWithTemplateDataResponse?.getAssembliesCount?.count ?? 0);
        setCurrentAssemblies((previousAssemblies) => {
          if (page === 0) {
            return assembliesWithTemplateDataResponse?.getAssembliesByTemplateFieldsFilters ?? [];
          }
          return [
            ...previousAssemblies,
            ...(assembliesWithTemplateDataResponse?.getAssembliesByTemplateFieldsFilters ?? []),
          ];
        });
      },
    });

  const fetchAssembliesData = useCallback(() => {
    if (structureId && selectedAssemblyTemplateId) {
      getAssemblies({
        variables: {
          input: {
            structureId,
            templateId: selectedAssemblyTemplateId,
            selectedTemplateFilters: appliedTemplateFilters,
            selectedPaintRegionIds: appliedPaintRegionFilters,
            selectedDeckIds: appliedDeckFilters,
          },
          pageInfo: {
            skip: page * rowsPerPage,
            limit: rowsPerPage,
          },
        },
      });
    }
  }, [
    structureId,
    selectedAssemblyTemplateId,
    appliedTemplateFilters,
    appliedPaintRegionFilters,
    appliedDeckFilters,
    page,
    getAssemblies,
  ]);

  useEffect(() => {
    fetchAssembliesData();
  }, [structureId, selectedAssemblyTemplateId, page, fetchAssembliesData, loadMoreAssemblies]);

  const selectedFilters = useRecoilValue(state.selectedRiskAssessmentFilters);

  const router = useRouter();
  const isAtESSheet = useMemo(() => router.pathname.includes('risk'), [router.pathname]);

  const { data: riskAssemblies, loading: riskAssembliesLoading } =
    useGetAssembliesByRiskAssessmentFiltersQuery({
      skip: !isAtESSheet || !selectedAssemblyTemplate?.id || !loadMoreAssemblies,
      nextFetchPolicy: 'cache-first',
      variables: {
        input: {
          structureId,
          templateId: selectedAssemblyTemplate?.id ?? '',
          selectedFilters,
          searchText: riskSearchTerm,
        },
        pageInfo: {
          skip: page * rowsPerPage,
          limit: rowsPerPage,
          sortBy: 'templateFieldData.risk_damage_class',
          sortDir: -1,
        },
      },
      onCompleted: () => {
        setLoadMoreAssemblies(false);
        setAssemblyCount(riskAssemblies?.getRiskAssembliesCount?.count ?? 0);
        setCurrentAssemblies((previousAssemblies) => {
          if (page === 0) {
            return riskAssemblies?.getAssembliesByRiskAssessmentFilters ?? [];
          }
          return [
            ...previousAssemblies,
            ...(riskAssemblies?.getAssembliesByRiskAssessmentFilters ?? []),
          ];
        });
      },
    });
  useAssemblyVisibilityBox(assemblies);
  useResetSelectedFilters();

  const selectedTemplate = useMemo(
    () => allTemplates?.find((template) => template.id === selectedAssemblyTemplateId),
    [allTemplates, selectedAssemblyTemplateId]
  );

  const isAtInsightsPage = useMemo(() => router.pathname.includes('insights'), [router.pathname]);

  const isAtViewerPage = useMemo(() => router.pathname.includes('viewer'), [router.pathname]);

  const isSelectedAssemblyExistOnPointCloud = useMemo(() => {
    if (
      selectedAssemblyInfo &&
      assemblies?.find((assembly) => assembly.id === selectedAssemblyInfo)
    ) {
      return true;
    }
    return false;
  }, [selectedAssemblyInfo, assemblies]);

  const { data: assemblyContextualizedStatus, loading: contextloading } =
    useGetAssemblyContextualizedStatusQuery({
      variables: {
        assemblyId: selectedAssemblyId!,
      },
      skip: !selectedAssemblyId,
    });

  // OK
  useEffect(() => {
    if (
      assemblyContextualizedStatus?.assembly?.isUncontextualized &&
      !contextloading &&
      isAtViewerPage
    ) {
      setSelectedAssemblyId(undefined);
      setSelectedAssemblyName(undefined);
      setSelectedAssemblyInfo(undefined);
      setSelectedTemplateFilters([]);
    }
  }, [
    assemblyContextualizedStatus?.assembly?.isUncontextualized,
    contextloading,
    setSelectedAssemblyId,
    setSelectedAssemblyName,
    isAtViewerPage,
    setSelectedAssemblyInfo,
    setSelectedTemplateFilters,
  ]);

  useEffect(() => {
    if (applyFilters) {
      setAppliedTemplateFilters(selectedTemplateFilters);
      setAppliedDeckFilters(selectedDeckIds);
      setAppliedPaintRegionFilters(selectedPaintRegionIds);
      setPage(0);
    }
  }, [applyFilters, selectedDeckIds, selectedPaintRegionIds, selectedTemplateFilters]);

  useEffect(() => {
    if (loadMoreAssemblies || assembliesDataLoading || riskAssembliesLoading) return;

    const container = scrollContainerReference.current;
    const content = scrollContentReference.current;

    if (container && content) {
      const { clientHeight: containerHeight } = container;
      const { clientHeight: contentHeight } = content;

      if (contentHeight < containerHeight && currentAssemblies.length < assemblyCount) {
        setLoadMoreAssemblies(true);
        setPage((previousPage) => previousPage + 1);
      }
    }
  }, [
    assembliesDataLoading,
    assemblyCount,
    currentAssemblies.length,
    isAtESSheet,
    loadMoreAssemblies,
    page,
    riskAssemblies,
    riskAssembliesLoading,
  ]);

  useEffect(() => {
    setPage(0);
    setLoadMoreAssemblies(true);
  }, [isAtESSheet, selectedFilters, riskSearchTerm, applyFilters]);

  const handleEquipmentCardClick = useCallback(
    (assemblyId: string, assemblyName: string) => {
      if (!isAtESSheet && !assemblies) return;

      if (assemblyId !== selectedAssemblyId) {
        setSelectedAssemblyId(assemblyId);
        setSelectedAssemblyName(assemblyName);
      }
    },
    [assemblies, isAtESSheet, selectedAssemblyId, setSelectedAssemblyId, setSelectedAssemblyName]
  );

  const highlightedAssemblyCardInfo = useMemo(() => {
    return isSelectedAssemblyExistOnPointCloud
      ? assemblies?.find((assembly) => assembly.id === selectedAssemblyInfo)
      : undefined;
  }, [assemblies, isSelectedAssemblyExistOnPointCloud, selectedAssemblyInfo]);

  const handleScroll = useCallback(() => {
    if (!scrollContainerReference.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollContainerReference.current;
    setIsAtScrollStart(scrollTop === 0);
    if (
      !assembliesDataLoading &&
      !riskAssembliesLoading &&
      !loadMoreAssemblies &&
      scrollTop + clientHeight + 24 >= scrollHeight &&
      currentAssemblies.length < assemblyCount
    ) {
      setLoadMoreAssemblies(true);
      setPage((previousPage) => previousPage + 1);
    }
  }, [
    assembliesDataLoading,
    assemblyCount,
    currentAssemblies.length,
    loadMoreAssemblies,
    riskAssembliesLoading,
  ]);

  useEffect(() => {
    const scrollContainer = scrollContainerReference.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleScrollToTop = useCallback(() => {
    if (scrollContainerReference.current) {
      scrollContainerReference.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  if (!selectedAssemblyId && isAtViewerPage && selectedSpherical) {
    return (
      <Box sx={{ marginTop: '60%' }}>
        <EmptyStatePlaceholder type={isAtInsightsPage ? 'equipmentTable' : 'equipmentViewer'} />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: abyssColors.primary[50], position: 'relative' }}>
      {(loading || loadMoreAssemblies) && page === 0 && <EquipmentTabSkeleton />}
      {((!loading && !loadMoreAssemblies) || page !== 0) && (
        <EquipmentTabContainer
          ref={scrollContainerReference}
          onScroll={handleScroll}
          topPosition={scrollContainerReference.current?.getBoundingClientRect().top ?? 0}
          data-testid="equipment-tab-container"
        >
          {selectedAssemblyId ? (
            <>
              <AllEquipmentBackButton
                changeTab={changeTab}
                retainAssemblyView={!!selectedSpherical}
                customLabel={isAtESSheet ? 'All equipments' : ''}
              />
              <EquipmentDetails
                assembly={assemblyDetails}
                isLoadingAssemblyDetails={assemblyDetailsLoading}
                isUncontextualized={!!assemblyDetails?.isUncontextualized}
              />
              {assemblyDetails && !assemblyDetails?.isUncontextualized && (
                <IsometricDownloadButton assembly={assemblyDetails} />
              )}
            </>
          ) : (
            <>
              <EquipmentCardsContainer data-testid="equipment-cards-container">
                <EquipmentHeader isAtScrollStart={isAtScrollStart}>
                  <Typography
                    fontSize="2.4rem"
                    color={abyssColors.primary[500]}
                    fontWeight={500}
                    lineHeight="normal"
                    display="inline-flex"
                    alignItems="flex-end"
                    gap="0.8rem"
                  >
                    {assemblyCount}
                    <span>
                      <Typography fontSize="1.4rem" color={abyssColors.primary[400]}>
                        {`Total ${isAtESSheet ? 'equipment' : 'components'} found`}
                      </Typography>
                    </span>
                  </Typography>
                </EquipmentHeader>
                {/* Display is set to none for now, will enable again when data is available for proper functionality */}
                <Box display={isAtESSheet ? 'block' : 'none'}>
                  <Box sx={{ px: '2.4rem', mb: '3.2rem', width: '100%' }}>
                    <TextField
                      variant="standard"
                      label="Search"
                      value={riskSearchTerm}
                      fullWidth
                      sx={{
                        '& .MuiInputLabel-root': { color: abyssColors.primary[300] },
                        '& .MuiInputLabel-root.Mui-focused': { color: abyssColors.primary[300] },
                        mb: '1.6rem',
                      }}
                      onChange={(event) => {
                        setRiskSearchTerm(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {riskSearchTerm && (
                              <CloseIcon
                                sx={{
                                  color: abyssColors.primary[300],
                                  mr: '0.5rem',
                                  cursor: 'pointer',
                                }}
                                onClick={() => setRiskSearchTerm('')}
                              />
                            )}
                            <SearchIcon sx={{ color: abyssColors.primary[300] }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Stack ref={scrollContentReference} gap={isAtESSheet ? '0.2rem' : 0}>
                  {highlightedAssemblyCardInfo && (
                    <EquipmentCard
                      sx={selectedAssemblyCardStyles}
                      key={`${highlightedAssemblyCardInfo.id}-${highlightedAssemblyCardInfo.tagName}`}
                      assembly={highlightedAssemblyCardInfo}
                      handleClick={() => {
                        handleEquipmentCardClick(
                          highlightedAssemblyCardInfo.id,
                          highlightedAssemblyCardInfo.tagName
                        );
                      }}
                    />
                  )}
                  {!isAtESSheet &&
                    currentAssemblies.map((assembly, index) => (
                      <EquipmentCard
                        equipmentCardTemplate={selectedTemplate?.equipmentCard}
                        key={`${assembly.id}-${index}-card`}
                        assembly={assembly}
                        handleClick={() => handleEquipmentCardClick(assembly.id, assembly.tagName)}
                      />
                    ))}
                  {isAtESSheet &&
                    currentAssemblies.map((assembly, index) => (
                      <RiskEquipmentCard
                        key={`risk-${assembly.id}-${index}-card`}
                        assembly={assembly}
                        handleClick={() => {
                          handleEquipmentCardClick(assembly.id, assembly.tagName);
                        }}
                      />
                    ))}
                </Stack>
              </EquipmentCardsContainer>
              {currentAssemblies.length < assemblyCount && (
                <Stack width="100%" alignItems="center" sx={{ marginBottom: '80px' }}>
                  <PageLoader hideProgress variant="sm" flashing />
                </Stack>
              )}

              {currentAssemblies.length === assemblyCount && (
                <EndMessage>End of results</EndMessage>
              )}

              <Fade in={!isAtScrollStart}>
                <Tooltip
                  title="Scroll to Top"
                  placement="top"
                  arrow
                  PopperProps={{
                    sx: tooltipPopperStyles,
                  }}
                >
                  <FloatingButton onClick={handleScrollToTop}>
                    <ArrowUpwardIcon />
                  </FloatingButton>
                </Tooltip>
              </Fade>
            </>
          )}
        </EquipmentTabContainer>
      )}
      {!loading && !selectedAssemblyId && (
        <ExportButtonContainer>
          <ExportAssetsButton structureId={structureId} />
        </ExportButtonContainer>
      )}
    </Box>
  );
};
