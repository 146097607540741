import { SetStateAction, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

export enum CrossSectionPlaneEnum {
  XZ = 0,
  YZ = 1,
}

const crossSectionPlaneName = (crossSectionPlane: CrossSectionPlaneEnum): string => {
  switch (crossSectionPlane) {
    case CrossSectionPlaneEnum.XZ:
      return 'X-Z Plane Cross Section'.toLocaleLowerCase();
    case CrossSectionPlaneEnum.YZ:
      return 'Y-Z Plane Cross Section'.toLocaleLowerCase();
  }
};

const crossSectionPlaneFromName = (name: string): CrossSectionPlaneEnum => {
  if (name.startsWith('x') || name.startsWith('X')) {
    return CrossSectionPlaneEnum.XZ;
  }
  return CrossSectionPlaneEnum.YZ;
};

type Props = {
  crossSectionPlane: CrossSectionPlaneEnum;
  setCrossSectionPlane: (value: SetStateAction<CrossSectionPlaneEnum>) => void;
};

export const CrossSectionPlaneSelector = ({ crossSectionPlane, setCrossSectionPlane }: Props) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const newValue = event.target.value;

      if (!newValue) {
        return;
      }

      setCrossSectionPlane(crossSectionPlaneFromName(newValue));
    },
    [setCrossSectionPlane]
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ marginTop: '20px' }}>
      <InfoIconOutlined sx={{ fontSize: '1.5rem' }} />
      <Typography
        sx={{ fontSize: '1.2rem', lineHeight: '1.5rem', verticalAlign: 'center', flexGrow: 1 }}
      >
        Cross-section graph
      </Typography>
      <FormControl variant="standard" sx={{ flexGrow: 1 }}>
        <Select
          variant="standard"
          id="CurrentCrossSectionPlane"
          onChange={handleChange}
          value={crossSectionPlaneName(crossSectionPlane)}
          // sx={{
          //   '& .MuiSelect-select': {
          //     paddingLeft: 1,
          //   },
          // }}
        >
          {[CrossSectionPlaneEnum.XZ, CrossSectionPlaneEnum.YZ].map((plane) => {
            const option = crossSectionPlaneName(plane);
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};
