import {
  DefectsAreaForCustomBlocksQuery,
  usePaintBlockPointsLazyQuery,
} from '@/__generated__/graphql';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import * as state from '../state';
import { prismToAnalysisCuboid } from '@/utils/geometry';
import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { useSnackBarMessage } from '@/utils/useSnackBarMessage';

type Props = {
  block: DefectsAreaForCustomBlocksQuery['defectsAreaForAssemblyPaintRegionsByGroup']['defectsByPaintBlock'][number];
};

export const CustomBlockListItem = memo(({ block }: Props) => {
  const [fetchPaintRegionPoints, { loading: loadingPaintRegion }] = usePaintBlockPointsLazyQuery();
  const [customBlockCuboids, setCustomBlockCuboids] = useRecoilState(state.customBlockCuboids);
  const { showSnackBar } = useSnackBarMessage({});

  const [hasInvalidPrism, setHasInvalidPrism] = useState(false);

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        // If the incoming value is false, the checkbox is being unchecked
        // so in that case remove the current cuboid from the list
        // doing so removes it from the 3D viewer
        const value = event.target.checked;
        if (!value) {
          setCustomBlockCuboids((current) => {
            const copy = current.asMutableStateMap();
            copy.delete(block.paintRegionId);
            return copy.asStateMap();
          });
          return;
        }

        const paintRegion = await fetchPaintRegionPoints({
          variables: {
            paintRegionId: block.paintRegionId,
          },
          // A block's 3D data NEVER changes so we can safely cache it
          fetchPolicy: 'cache-first',
        });
        const prism = paintRegion?.data?.paintRegion?.volume?.inclusion;
        if (!prism || !prism?.z_range || !prism?.xy_polygon) {
          showSnackBar('Missing 3D data. Please contact support', 'error');
          return;
        }

        const cuboid = prismToAnalysisCuboid(prism, block.paintRegionId);
        setCustomBlockCuboids((current) => {
          const copy = current.asMutableStateMap();
          copy.set(block.paintRegionId, cuboid);
          return copy.asStateMap();
        });
      } catch (error) {
        setHasInvalidPrism(true);
        console.error(error);
      }
    },
    [block.paintRegionId, fetchPaintRegionPoints, setCustomBlockCuboids, showSnackBar]
  );

  const totalCorrodedArea = useMemo(() => {
    const value = block.totalArea?.find((item) => item?.type === 'totalCorrodedArea')?.value;
    if (!value) return undefined;
    if (typeof value !== 'number') return undefined;
    return Number.parseFloat(value.toFixed(2));
  }, [block.totalArea]);
  const area = useMemo(() => {
    const value = block.totalArea?.find((item) => item?.type === 'area')?.value;
    if (!value) return undefined;
    if (typeof value !== 'number') return undefined;
    return Number.parseFloat(value.toFixed(2));
  }, [block.totalArea]);

  const isSelected = useMemo(
    () => customBlockCuboids.has(block.paintRegionId),
    [block.paintRegionId, customBlockCuboids]
  );

  return (
    <Stack direction={'row'} width={'100%'} px={1} mb={1}>
      <FormControlLabel
        sx={{ color: 'primary.main', fontSize: '1px' }}
        control={
          <Checkbox
            id={block.paintRegionId}
            disabled={hasInvalidPrism || loadingPaintRegion}
            checked={isSelected}
            onChange={onChange}
          />
        }
        label={
          <Typography fontSize={'14px'} color="primary.main">
            {block.name}
          </Typography>
        }
      />

      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={'3px'}
        ml={'auto'}
        sx={{ color: 'primary.main', fontWeight: 500, fontSize: '12px' }}
      >
        <p>{totalCorrodedArea}</p>
        <p>/</p>
        <p>{area}</p>
      </Stack>
    </Stack>
  );
});
