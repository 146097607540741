import { abyssColors } from '@/theme/colors';
import { ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

export const PaintBlockSelectionPill = styled(ToggleButton)(({ theme }) => ({
  display: 'flex',
  padding: '4px 8px',
  border: `1px solid ${abyssColors.primary[200]}`,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14.06px',
  width: 'fit-content',
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    color: '#fff',
  },
}));

export const PaintBlockSelectionPillCounter = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.info.main,
  padding: '2px 4px',
  marginRight: '5px',
  marginLeft: '5px',
  borderRadius: '5px',
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '1%',
  width: '100%',
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton} ,& .${toggleButtonGroupClasses.firstButton}`]:
    {
      border: `1px solid ${abyssColors.primary[200]}`,
      borderRadius: '5px',
    },
}));
