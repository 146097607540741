import { Box, Divider, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useCallback, useMemo, useState } from 'react';
import { GenericAccordion } from '@/components/shared/GenericAccordion';
import * as state from '@/components/Analysis/state';
import {
  Maybe,
  ValueAndPercentage,
  useStructureWallLossFactorQuery,
} from '@/__generated__/graphql';

import { FieldViewer } from '../../FieldViewer';
import {
  AitUserVerifiedPoiView,
  InspectorBlisterEditIconButton,
  InspectorVerifiedFieldsAccordian,
  InspectorVerifiedPoiView,
} from './DetailsTabViews';
import { PointOfInterest, PointOfInterestTemplate } from '@/__generated__/graphql';
import {
  convertInchestoMillimetersDisplay,
  convertMillimetersToInchesDisplay,
  localiseBlisterHeight,
} from '@/utils/unitSystem';
import { useFeatureFlag } from '@/hooks';
import { TemplateFieldTypeEnum } from '@/types';
import {
  UNCERTAIN_PROFILE_LABEL,
  PIT_DEPTH_LABEL,
  BLISTER_HEIGHT_LABEL,
  BLISTER_TYPE,
  PIT_TYPE,
} from '@/constants';
import { AttachmentsViewer } from '../AttachmentsViewer';
import { UnitSystemEnum } from '@/__generated__/graphql';
import { Attachment } from '../../types';
import { MeasureAutoPointOfInterest } from '../MeasureAutoPointOfInterest';
import { useNavbarHeight } from '@/hooks/useNavbarHeight';
import { useGetStructureId } from '@/hooks/useGetStructureId';

const accordianProps = {
  headingStyles: {
    fontWeight: 'bold',
  },
};

type Props = {
  disableEdit?: boolean;
  selectedPoiInfo: PointOfInterest;
};

const boxRootStyle = {
  paddingBottom: '2.4rem',
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const DetailsTab = ({ disableEdit, selectedPoiInfo }: Props) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const allPointOfInterestTemplates = useRecoilValue(state.allPointOfInterestTemplates);
  const allTemplates = useRecoilValue(state.allAssemblyTemplates);
  const selectedTemplateId = useRecoilValue(state.selectedAssemblyTemplateId);
  const selectedAssemblyId = useRecoilValue(state.selectedAssemblyId);
  const unitSystem = useRecoilValue(state.unitSystem);
  const navbarHeight = useNavbarHeight();
  const structureId = useGetStructureId();

  const { data: structureData } = useStructureWallLossFactorQuery({
    variables: { structureId },
    skip: !structureId,
  });

  const structureWallLossFactor = useMemo(() => {
    const wallLossFactor = structureData?.structure?.wallLossFactor;
    if (!wallLossFactor) return 'N/A';
    return String(wallLossFactor);
  }, [structureData]);

  const scCategoryExists = useMemo(() => {
    const assemblyTemplate = allTemplates?.find((template) => template.id === selectedTemplateId);
    return assemblyTemplate?.fields.some((field) => field.key === 'governing_sc_category');
  }, [allTemplates, selectedTemplateId]);

  const templateOfSelectedPoi = useMemo(() => {
    return allPointOfInterestTemplates.find(({ id }) => id === selectedPoiInfo.template?.id);
  }, [allPointOfInterestTemplates, selectedPoiInfo]);

  // Need to render the comments separetely
  const templateFields = useMemo(() => {
    return templateOfSelectedPoi?.fields?.filter((field) => field.name !== 'Comments') || [];
  }, [templateOfSelectedPoi]);

  const formatValue = useCallback(
    (data: Maybe<ValueAndPercentage> | undefined) => {
      if (!data) return 'N/A';
      const { value, percentage } = data;
      if (!value && !percentage) return 'N/A';
      const convertedValue = localiseBlisterHeight(unitSystem, value);
      if (!percentage) return `${convertedValue}`;
      return `${convertedValue} (${percentage.toFixed(1)}%)`;
    },
    [unitSystem]
  );

  const templateFieldsWithValues: Array<{
    label: string;
    value: string;
    type?: TemplateFieldTypeEnum;
    valueTooltip?: string;
  }> = useMemo(() => {
    let blisterInfo: { value: string; label: string }[] = [];
    if (selectedPoiInfo.blister) {
      const { maxHeight, wallLoss, residualCorrosionAllowance, residualThickness, scCategory } =
        selectedPoiInfo.blister || {};
      const label = selectedPoiInfo.type === BLISTER_TYPE ? BLISTER_HEIGHT_LABEL : PIT_DEPTH_LABEL;
      blisterInfo = [
        {
          value: localiseBlisterHeight(unitSystem, maxHeight) ?? UNCERTAIN_PROFILE_LABEL,
          label: label,
        },
        { value: formatValue(wallLoss), label: 'Estimated Wall Loss' },
        { value: structureWallLossFactor, label: 'Estimated Wall Loss Factor' },
        { value: formatValue(residualCorrosionAllowance), label: 'Residual Corrosion Allowance' },
        { value: formatValue(residualThickness), label: 'Residual Thickness' },
      ];

      if (scCategoryExists) {
        blisterInfo.push({ value: scCategory ?? 'N/A', label: 'SC Category' });
      }
    }

    const templateInfo = templateFields?.map((field) => {
      const value = selectedPoiInfo.templateData?.find((td) => td.id === field.id)?.value ?? 'N/A';
      return {
        value,
        label: field?.name || 'N/A',
        type: field?.type,
        valueTooltip: value,
      };
    });
    return [...blisterInfo, ...templateInfo];
  }, [
    formatValue,
    structureWallLossFactor,
    scCategoryExists,
    selectedPoiInfo.blister,
    selectedPoiInfo.templateData,
    selectedPoiInfo.type,
    templateFields,
    unitSystem,
  ]);
  const verifiedFieldsWithValues: Array<{ label: string; value: string }> = useMemo(() => {
    const verifiedData = templateFields?.map((field) => {
      const value =
        selectedPoiInfo.templateData?.find((td) => td.id === field.id)?.verifiedValue ?? 'N/A';

      return {
        value,
        label: field?.name || 'N/A',
      };
    });

    if (selectedPoiInfo?.blister?.verifiedMaxHeight) {
      let verifiedHeight = selectedPoiInfo?.blister.verifiedMaxHeight;
      if (
        selectedPoiInfo?.blister.verifiedMaxHeight.endsWith('"') &&
        unitSystem === UnitSystemEnum.Metric
      ) {
        verifiedHeight =
          convertInchestoMillimetersDisplay(
            Number(selectedPoiInfo?.blister.verifiedMaxHeight.slice(0, -1))
          ) ?? UNCERTAIN_PROFILE_LABEL;
      } else if (
        selectedPoiInfo?.blister.verifiedMaxHeight.endsWith('mm') &&
        unitSystem === UnitSystemEnum.Imperial
      ) {
        verifiedHeight =
          convertMillimetersToInchesDisplay(
            Number(selectedPoiInfo?.blister.verifiedMaxHeight.slice(0, -2))
          ) ?? UNCERTAIN_PROFILE_LABEL;
      }

      verifiedData.push({
        label: 'Verified Max Height',
        value: verifiedHeight,
      });
    }
    if (selectedPoiInfo?.blister?.verifiedResidualThickness) {
      let verifiedThickness = selectedPoiInfo?.blister.verifiedResidualThickness;
      if (
        selectedPoiInfo?.blister.verifiedResidualThickness.endsWith('"') &&
        unitSystem === UnitSystemEnum.Metric
      ) {
        verifiedThickness =
          convertInchestoMillimetersDisplay(
            Number(selectedPoiInfo?.blister.verifiedResidualThickness.slice(0, -1))
          ) ?? UNCERTAIN_PROFILE_LABEL;
      } else if (
        selectedPoiInfo?.blister.verifiedResidualThickness.endsWith('mm') &&
        unitSystem === UnitSystemEnum.Imperial
      ) {
        verifiedThickness =
          convertMillimetersToInchesDisplay(
            Number(selectedPoiInfo?.blister.verifiedResidualThickness.slice(0, -2))
          ) ?? UNCERTAIN_PROFILE_LABEL;
      }
      verifiedData.push({
        label: 'Verified Residual Thickness',
        value: verifiedThickness,
      });
    }
    return verifiedData;
  }, [
    selectedPoiInfo?.blister?.verifiedMaxHeight,
    selectedPoiInfo?.blister?.verifiedResidualThickness,
    selectedPoiInfo.templateData,
    templateFields,
    unitSystem,
  ]);

  const isInspector = useFeatureFlag('api-inspector');
  const canVerifyData = useFeatureFlag(['ait-engineer', 'client', 'ait-task-lead']);

  // Only show the edit icon for the API Inspector
  // if its a Blister or Pit
  const showEditIconForInspector = useMemo(() => {
    const excludedTypes = [PIT_TYPE, BLISTER_TYPE];
    return excludedTypes.includes(selectedPoiInfo.type) && isInspector;
  }, [isInspector, selectedPoiInfo.type]);

  const isVerified = selectedPoiInfo.isVerified;

  const isUninitialized = useMemo(() => {
    return selectedPoiInfo.type === BLISTER_TYPE && !selectedPoiInfo.blister;
  }, [selectedPoiInfo.blister, selectedPoiInfo.type]);

  return (
    <Box sx={{ ...boxRootStyle, height: `calc(100vh - ${navbarHeight + 140}px)` }}>
      <MeasureAutoPointOfInterest pointOfInterest={selectedPoiInfo} />
      {/* If an AIT member sees a POI thats not verified by an inspector yet */}
      {!isInspector && !isVerified ? (
        <AitUserVerifiedPoiView
          pointOfInterest={selectedPoiInfo}
          fields={templateFieldsWithValues}
          template={templateOfSelectedPoi as PointOfInterestTemplate}
          disableEdit={
            !selectedAssemblyId || (disableEdit && !!selectedPoiInfo.blister) || isUninitialized
          }
        />
      ) : (
        <GenericAccordion
          summary="Pre Inspection Data"
          summaryStyles={{ fontWeight: 500 }}
          openByDefault
          summaryIcon={
            showEditIconForInspector ? (
              <InspectorBlisterEditIconButton
                pointOfInterest={selectedPoiInfo}
                template={templateOfSelectedPoi as PointOfInterestTemplate}
              />
            ) : (
              <></>
            )
          }
          {...accordianProps}
        >
          <FieldViewer fields={templateFieldsWithValues} />
        </GenericAccordion>
      )}

      {canVerifyData && isVerified && (
        <>
          <Divider />
          <InspectorVerifiedPoiView
            fields={verifiedFieldsWithValues}
            pointOfInterest={selectedPoiInfo}
            template={templateOfSelectedPoi as PointOfInterestTemplate}
            disableEdit={false || !selectedAssemblyId || isUninitialized}
          />
        </>
      )}

      {canVerifyData && !isVerified && (
        <InspectorVerifiedFieldsAccordian
          canVerifyData={canVerifyData}
          fields={verifiedFieldsWithValues}
          accordianProps={accordianProps}
          pointOfInterest={selectedPoiInfo}
          template={templateOfSelectedPoi as PointOfInterestTemplate}
        />
      )}
      <Divider />

      {attachments.length > 0 && (
        <Typography variant="h6" sx={{ mb: 2, px: 2 }}>
          Attachments
        </Typography>
      )}
      <AttachmentsViewer
        attachments={attachments}
        setAttachments={setAttachments}
        selectedPoiInfo={selectedPoiInfo}
      />
    </Box>
  );
};
